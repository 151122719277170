<template>
    <div class="k-flex-col">
        <b-form-group>
            <label> <b>Nom</b> <span class="text-danger">*</span> </label>
            <b-form-input
                v-model="localSelection.name"
                :state="isNameValid"
                trim
            ></b-form-input>
            <b-form-invalid-feedback :state="isNameValid">
                Veuillez saisir le nom de la sélection
            </b-form-invalid-feedback>
        </b-form-group>
    </div>
</template>

<script>
import Vue from "vue";
import notifier from "@/tools/notifier";

const defaultSelection = {
    name: null,
};

export default {
    props: {
        sit: { type: String, required: true },
        project: { type: Object, required: true },
        selection: { type: Object, required: false },
    },

    data: function() {
        return {
            isEditMode: !!this.selection,
            localSelection: Vue.util.extend(
                {},
                this.selection || defaultSelection,
            ),
        };
    },

    computed: {
        isNameValid() {
            if (this.localSelection.name === null) {
                return null;
            } else {
                return this.localSelection.name !== "";
            }
        },

        isValid() {
            return this.isNameValid;
        },
    },

    methods: {
        async save() {
            if (!this.isValid) {
                notifier.error("Il y a au moins un champ invalide.");
                this.localSelection.name = this.localSelection.name || "";
                return false;
            }
            if (this.isEditMode) {
                await this.$store.dispatch("kbsit/updateSelection", {
                    ...this.localSelection,
                    sit: this.sit,
                    projectId: this.project.id,
                    selectionId: this.selection.id,
                });
            } else {
                await this.$store.dispatch("kbsit/createSelection", {
                    ...this.localSelection,
                    sit: this.sit,
                    projectId: this.project.id,
                });
            }
            return true;
        },
    },
};
</script>
