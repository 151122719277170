<template>
    <div class="k-flex-col">
        <b-form-group>
            <label>
                <b>Nom du projet</b> <span class="text-danger">*</span>
            </label>
            <b-form-input
                v-model="localProject.name"
                :state="isNameValid"
                debounce="500"
                @change="update"
                trim
            ></b-form-input>
            <b-form-invalid-feedback :state="isNameValid">
                Veuillez saisir le nom du projet
            </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
            <label>
                <b>Traductions</b>
            </label>
            <b-form-checkbox-group
                v-model="localProject.meta.langs"
                @change="update"
                :options="langs"
            >
            </b-form-checkbox-group>
        </b-form-group>

        <b-form-group>
            <label>
                <b>Options</b>
            </label>
            <b-form-checkbox-group
                v-model="selectedOptions"
                :options="listOptions"
                @change="update"
                stacked
                switches
            ></b-form-checkbox-group>
        </b-form-group>

        <template v-if="isEditMode">
            <b-form-group>
                <label>
                    <b>Collections de médias</b>
                </label>
                <GridCardBasic
                    :data="mediaCollections"
                    empty="Aucun fichier"
                    minCardSize="200px"
                >
                    <template v-slot="{ data }">
                        <div class="k-card-1 text-center k-flex-col">
                            {{ data.name }}
                            <sup>
                                <b-badge v-if="!data.required">
                                    non nécessaire
                                </b-badge>
                                <b-badge v-else variant="danger">
                                    obligatoire
                                </b-badge>
                            </sup>

                            <small class="border rounded p-1">
                                {{ data.mime || "Pas de restriction MIME" }}
                            </small>

                            <div
                                class="k-flex-row k-flex-fill"
                                v-if="data.collection"
                            >
                                <b-button
                                    size="sm"
                                    variant="secondary"
                                    disabled
                                >
                                    <b-icon-check /> Existe
                                </b-button>
                                <small>#{{ data.collection }}</small>
                            </div>
                            <b-button
                                v-else
                                size="sm"
                                variant="kalkin-2"
                                :disabled="!data.required"
                                @click="initCollection(data)"
                            >
                                <b-icon-lightning /> Initialiser
                            </b-button>
                        </div>
                    </template>
                </GridCardBasic>
            </b-form-group>

            <b-button
                class="w-100"
                v-b-toggle.config-edit-advanced
                variant="kalkin-1"
                size="sm"
            >
                Configuration avancée <b-icon-gear-fill />
            </b-button>
            <b-collapse id="config-edit-advanced" class="k-collapse">
                <b-form-group>
                    <label>Configuration brute</label>
                    <JSONTextArea
                        v-model="rawMeta"
                        @input="update"
                        @validate="isConfigValid = $event"
                    />
                </b-form-group>
            </b-collapse>
        </template>
    </div>
</template>

<script>
import { cloneDeep as _cloneDeep } from "lodash";
import GridCardBasic from "@/components/ui/GridCardBasic";
import JSONTextArea from "@/components/ui/item/JSONTextArea";
import notifier from "@/tools/notifier";
import Langs from "@/config/lang";

export default {
    components: {
        GridCardBasic,
        JSONTextArea,
    },

    props: {
        sit: { type: String, required: true },
        format: { type: Object, required: true },
        project: { type: Object, required: false },
    },

    data: function() {
        return {
            localProject: this.initLocalProject(),
            isConfigValid: true,
        };
    },

    watch: {
        project() {
            this.localProject = this.initLocalProject();
        },
    },

    computed: {
        isEditMode() {
            return !!this.project;
        },

        langs() {
            return Langs.map(l => {
                return {
                    html: `
                    <div class="k-flex-row text-kalkin-1">
                        <small>${l.name}</small>
                        <span class="flag:${l.key}" />
                    </div>`,
                    value: l.key,
                    disabled: l.key === "fr",
                };
            });
        },

        rawMeta: {
            get() {
                return JSON.stringify(this.localProject.meta);
            },
            set(value) {
                this.localProject.meta = JSON.parse(value);
            },
        },

        selectedOptions: {
            get() {
                return this.localProject.meta.options || [];
            },
            set(value) {
                this.localProject.meta.options = value;
            },
        },

        listOptions() {
            return Object.entries(this.format.options).map(([k, v]) => ({
                text: v.name,
                value: k,
            }));
        },

        isNameValid() {
            if (this.localProject.name === null) {
                return null;
            } else {
                return this.localProject.name !== "";
            }
        },

        isValid() {
            return this.isNameValid && this.isConfigValid;
        },

        mediaCollections() {
            const collections = {};

            // Collection obligatoires
            for (const col of this.format.medias) {
                collections[col.id] = {
                    ...col,
                    collection: this.localProject.meta.medias[col.id],
                    required: true,
                };
            }

            // Collection optionnelles
            for (const [id, opt] of Object.entries(this.format.options)) {
                if (Array.isArray(opt.medias) && opt.medias.length) {
                    for (const col of opt.medias) {
                        let exCol = collections[col.id];
                        let required = (
                            this.localProject.meta.options || []
                        ).includes(id);

                        if (exCol) {
                            exCol.required = exCol.required || required;
                        } else {
                            collections[col.id] = {
                                ...col,
                                collection: this.localProject.meta.medias[
                                    col.id
                                ],
                                required,
                            };
                        }
                    }
                }
            }

            return Object.values(collections);
        },
    },

    methods: {
        initLocalProject() {
            let p = this.project ? _cloneDeep(this.project) : { name: "" };
            p.meta = p.meta || {};
            p.meta.medias = p.meta.medias || {};
            p.meta.langs = p.meta.langs || ["fr"];
            return p;
        },

        async update() {
            if (this.isValid && this.isEditMode) {
                await this.$store.dispatch("kbsit/updateProject", {
                    sit: this.sit,
                    projectId: this.localProject.id,
                    name: this.localProject.name,
                    meta: this.localProject.meta,
                });
                this.$emit("saved");
            }
        },

        async save() {
            if (!this.isValid) {
                notifier.error("Il y a au moins un champ invalide.");
                return false;
            }
            if (!this.isEditMode) {
                await this.$store.dispatch("kbsit/createProject", {
                    sit: this.sit,
                    name: this.localProject.name,
                    meta: this.localProject.meta,
                });
                this.$emit("saved");
                return true;
            }
        },

        async initCollection(data) {
            await this.$store.dispatch("kbsit/createMediaCollection", {
                sit: this.sit,
                projectId: this.localProject.id,
                data,
            });
            this.$emit("saved");
        },
    },
};
</script>
