<template>
    <div class="k-flex-col">
        <div class="k-flex-row">
            <div class="flex-grow-1 mr-4">
                <b-input-group>
                    <template #prepend>
                        <b-input-group-text>
                            <b-icon-search />
                        </b-input-group-text>
                    </template>
                    <b-form-input
                        placeholder="Rechercher une fiche"
                        v-model="filter"
                        debounce="500"
                    />
                </b-input-group>
            </div>

            <div class="d-flex flex-column">
                <small class="text-kalkin-1 text-center w-100">
                    Fiches par page
                </small>
                <b-form-group>
                    <b-form-radio-group
                        v-model="perPage"
                        size="sm"
                        button-variant="outline-kalkin-1"
                        buttons
                    >
                        <b-form-radio :value="15">15</b-form-radio>
                        <b-form-radio :value="30">30</b-form-radio>
                        <b-form-radio :value="60">60</b-form-radio>
                        <b-form-radio :value="120">120</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>

            <div>
                <b-button
                    variant="kalkin-2"
                    size="sm"
                    v-b-modal.ModalCreateFiche
                >
                    <b-icon-plus-circle />
                    Nouvelle fiche
                </b-button>
            </div>
        </div>
        <b-table
            ref="ficheTable"
            class="text-break text-center"
            :items="dataProvider"
            :fields="fields"
            :current-page="currentPage"
            :filter="filter"
            :per-page="perPage"
            sort-by="name"
            show-empty
            empty-text="Aucune fiche"
            empty-filtered-text="Aucune fiche trouvée"
            stacked="md"
            hover
            tbody-tr-class="pointer"
            @row-clicked="onFicheSelected"
            striped
            small
        >
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Chargement des données...</strong>
                </div>
            </template>
            <template #cell(name)="data">
                <span
                    class="pl-2"
                    :class="{ 'text-kalkin-1': data.item.visible }"
                >
                    {{ data.item.name }}
                </span>
            </template>
            <template #cell(createdAt)="data">
                {{ data.item.createdAt | dateFromISO(true) }}
            </template>
            <template #cell(updatedAt)="data">
                {{ data.item.updatedAt | dateFromISO(true) }}
            </template>
            <template #cell(visible)="data">
                <b-icon-eye-fill v-if="data.item.visible" variant="kalkin-2" />
                <b-icon-x-lg v-else variant="danger" />
            </template>
        </b-table>
        <div>
            <b-pagination
                class="m-auto"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalRows"
                align="center"
                size="sm"
                first-number
                last-number
            />
        </div>

        <ModalCreateFiche
            :sit="sit"
            :format="format"
            :project="project"
            @saved="onTableChange"
        />
        <ModalUpdateFiche
            :sit="sit"
            :format="format"
            :project="project"
            :fiche="selectedFiche"
            @saved="onTableChange"
        />
    </div>
</template>

<script>
import ModalCreateFiche from "./ModalCreateFiche";
import ModalUpdateFiche from "./ModalUpdateFiche";
export default {
    components: {
        ModalCreateFiche,
        ModalUpdateFiche,
    },

    props: {
        sit: { type: String, required: true },
        format: { type: Object, required: true },
        project: { type: Object, required: true },
    },

    data: function() {
        return {
            totalRows: 0,
            currentPage: 1,
            perPage: 15,
            fields: [
                {
                    key: "name",
                    label: "Nom de la fiche",
                    sortable: true,
                    tdClass: "text-danger text-left",
                },
                {
                    key: "visible",
                    label: "Visible",
                    sortable: true,
                },
                {
                    key: "createdAt",
                    label: "Création",
                    sortable: true,
                },
                {
                    key: "updatedAt",
                    label: "Modification",
                    sortable: true,
                },
            ],
            filter: "",

            selectedFiche: null,
        };
    },

    watch: {
        async filter() {
            await this.fetchTotalRows();
        },
    },

    methods: {
        async fetchTotalRows() {
            this.totalRows = await this.$store.dispatch("kbsit/countFSPItems", {
                sit: this.sit,
                projectId: this.project.id,
                filter: `name substring ${this.filter}`,
            });
        },

        async dataProvider(ctx) {
            const items = await this.$store.dispatch("kbsit/getFSPItems", {
                sit: this.sit,
                projectId: this.project.id,
                filter: `name substring ${this.filter}`,
                sortby: (ctx.sortDesc ? "-" : "") + ctx.sortBy,
                limit: this.perPage,
                offset: this.perPage * (this.currentPage - 1),
            });
            return items;
        },

        onFicheSelected(fiche) {
            this.selectedFiche = fiche;
            if (fiche) {
                this.$bvModal.show("ModalUpdateFiche");
            }
        },

        async onTableChange() {
            this.selectedFiche = null;
            this.$refs.ficheTable.refresh();
        },
    },

    async beforeMount() {
        await this.fetchTotalRows();
    },
};
</script>
