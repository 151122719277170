<template>
    <div class="k-flex-col">
        <template v-if="isEnabled">
            <label>
                <b>Document Google Sheets associé</b>
            </label>
            <div class="k-flex-row">
                <b-input
                    class="flex-grow-1 w-auto"
                    size="sm"
                    :value="url"
                    :disabled="true"
                />
                <b-button
                    variant="outline-secondary"
                    size="sm"
                    @click="copyURL"
                >
                    <b-icon-files /> Copier l'URL
                </b-button>
                <b-button
                    variant="kalkin-2"
                    size="sm"
                    target="_blank"
                    :href="url"
                >
                    <b-icon-box-arrow-up-right /> Ouvrir
                </b-button>
            </div>

            <h4 class="mt-3">Actions disponibles</h4>

            <div class="k-flex-col k-flex-center mt-3 text-center">
                <b-button
                    class="text-nowrap"
                    variant="outline-kalkin-1"
                    :disabled="isLoading"
                    @click="$bvModal.show('TabGsheet.onSave')"
                >
                    <b-icon-cloud-download /> Importer les données du document
                    Google Sheets
                    <b-spinner v-show="isLoading" small variant="kalkin-1" />
                </b-button>

                <div>
                    Cette action permet de récupérer les données contenues dans
                    le document Google Sheets pour les intégrer dans le SIT
                    Kalkin. Une nouvelle ligne produira une nouvelle fiche, et
                    les fiches existantes seront mises à jour avec le contenu du
                    document.
                </div>
                <small class="text-danger">
                    Attention : en cas d'erreur, vérifier l'intégrité du contenu
                    du document. Notamment le fait qu'il ne doit pas y avoir de
                    ligne vide au milieu du contenu.
                </small>

                <b-button
                    class="text-nowrap mt-3"
                    variant="outline-kalkin-1"
                    :disabled="isLoading"
                    @click="$bvModal.show('TabGsheet.onUpdate')"
                >
                    <b-icon-cloud-upload /> Mettre à jour le document Google
                    Sheets
                    <b-spinner v-show="isLoading" small variant="kalkin-1" />
                </b-button>

                <div>
                    Cette action permet d'exporter les données depuis le SIT
                    Kalkin vers le document Google Sheets.
                </div>
                <small class="text-danger">
                    Attention : toutes les données du document Google Sheets qui
                    n'ont pas été sauvegardées seront écrasées.
                </small>
            </div>
        </template>
        <div v-else class="k-flex-col k-flex-center k-gap-l py-5">
            <b class="text-danger">Google sheets non initialisé</b>

            <b-button
                class="k-flex-row"
                size="xl"
                variant="kalkin-2"
                :disabled="isLoading"
                @click="initGsheets"
            >
                Initialiser le document
                <b-spinner v-show="isLoading" small variant="kalkin-1" />
            </b-button>
        </div>

        <ModalDanger
            uid="TabGsheet.onSave"
            @confirmed="callGsheets('save')"
            message="Êtes-vous sûr de vouloir récupérer les données?"
            details="Toutes les données présente dans Google Sheets seront enregistrées en base de données, écrasant ainsi les données existantes."
        />

        <ModalDanger
            uid="TabGsheet.onUpdate"
            @confirmed="callGsheets('update')"
            message="Êtes-vous sûr de vouloir mettre à jour le document Google Sheets?"
            details="Toutes les données du document qui n'ont pas été sauvegardées seront écrasées."
        />
    </div>
</template>

<script>
import ModalDanger from "@/components/modal/ModalDanger";
import notifier from "@/tools/notifier";

export default {
    components: {
        ModalDanger,
    },

    props: {
        sit: { type: String, required: true },
        format: { type: Object, required: true },
        project: { type: Object, required: true },
    },

    data: function() {
        return {
            isLoading: false,
        };
    },

    computed: {
        isEnabled() {
            return this.project.meta && this.project.meta.sheet;
        },

        url() {
            if (this.isEnabled) {
                return `https://docs.google.com/spreadsheets/d/${this.project.meta.sheet}`;
            } else {
                return "";
            }
        },
    },

    methods: {
        async initGsheets() {
            this.isLoading = true;
            if (
                await this.$store.dispatch("kbsit/runGsheetsCommand", {
                    sit: this.sit,
                    projectId: this.project.id,
                    command: "init",
                })
            ) {
                await this.$store.dispatch("kbsit/runGsheetsCommand", {
                    sit: this.sit,
                    projectId: this.project.id,
                    command: "update",
                });
            }

            this.$emit("refresh");
            this.isLoading = false;
        },

        async callGsheets(command) {
            this.isLoading = true;
            await this.$store.dispatch("kbsit/runGsheetsCommand", {
                sit: this.sit,
                projectId: this.project.id,
                command,
            });
            this.isLoading = false;
        },

        copyURL() {
            navigator.clipboard.writeText(this.url);
            notifier.saved("Copié dans le presse-papier!");
        },
    },

    async beforeMount() {},
};
</script>
