<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1><small>Projet</small> {{ project.name }}</h1>
            <h4>
                <b-badge variant="kalkin-1">SIT {{ sit }}</b-badge>
            </h4>
        </div>

        <b-tabs
            class="border rounded bg-light"
            nav-class="pt-1 px-3"
            content-class="p-3 bg-white"
            v-model="currentTab"
            lazy
        >
            <b-tab>
                <template #title> <b-icon-house-door-fill /> </template>
                <div>
                    <p>
                        Le projet a été créé le
                        {{ project.createdAt | dateFromISO(true) }}.
                    </p>
                    <p>
                        Vous pouvez
                        <b-link class="text-kalkin-2" @click="currentTab = 1">
                            créer, modifier ou supprimer des fiches.
                        </b-link>
                    </p>
                    <p>
                        Vous pouvez également
                        <b-link class="text-kalkin-2" @click="currentTab = 2">
                            créer, modifier ou supprimer des sélections
                        </b-link>
                        de fiches et
                        <b-link class="text-kalkin-2" @click="currentTab = 3">
                            associer les fiches et les sélections
                        </b-link>
                        entre elles.
                    </p>

                    <p v-if="project.meta && project.meta.sheet">
                        Le projet est lié à un document
                        <b-link class="text-kalkin-2" @click="currentTab = 4">
                            Google Sheets.
                        </b-link>
                    </p>
                    <p v-else>
                        Le projet ne possède pas de liaison
                        <b-link class="text-kalkin-2" @click="currentTab = 4">
                            Google Sheets. </b-link
                        >.
                    </p>
                </div>
            </b-tab>
            <b-tab>
                <template #title>
                    <b><b-icon-file-richtext /> Fiches </b>
                </template>
                <TabFiches :sit="sit" :format="format" :project="project" />
            </b-tab>
            <b-tab>
                <template #title>
                    <b><b-icon-journals /> Sélections </b>
                </template>
                <TabSelections :sit="sit" :project="project" />
            </b-tab>
            <b-tab>
                <template #title>
                    <b><b-icon-link45deg /> Associations </b>
                </template>
                <TabAsso :sit="sit" :project="project" />
            </b-tab>
            <b-tab>
                <template #title> <b-icon-google /> Sheets </template>
                <TabGsheets
                    :sit="sit"
                    :format="format"
                    :project="project"
                    @refresh="fetchProject"
                />
            </b-tab>
            <b-tab>
                <template #title> <b-icon-gear /> Configuration </template>
                <TabConfig
                    :sit="sit"
                    :format="format"
                    :project="project"
                    @refresh="fetchProject"
                />
            </b-tab>
            <template #tabs-end>
                <li class="nav-item flex-grow-1"></li>
                <li class="nav-item"></li>
            </template>
        </b-tabs>
    </div>
</template>

<script>
import TabFiches from "@/components/misc/admin-kbsit/fiche/TabFiches";
import TabSelections from "@/components/misc/admin-kbsit/selection/TabSelections";
import TabAsso from "@/components/misc/admin-kbsit/asso/TabAsso";
import TabGsheets from "@/components/misc/admin-kbsit/project/TabGsheets";
import TabConfig from "@/components/misc/admin-kbsit/project/TabConfig";

export default {
    components: {
        TabFiches,
        TabSelections,
        TabAsso,
        TabGsheets,
        TabConfig,
    },

    data: function() {
        return {
            sit: this.$route.params.sit,
            projectId: this.$route.params.projectId,

            format: {},
            project: {},

            currentTab: 0,
        };
    },

    methods: {
        async fetchFormat() {
            this.format = await this.$store.dispatch("kbsit/getFormat", {
                sit: this.sit,
            });
        },

        async fetchProject() {
            this.project = await this.$store.dispatch("kbsit/getProject", {
                sit: this.sit,
                projectId: this.projectId,
            });
        },
    },

    async beforeMount() {
        await this.fetchFormat();
        await this.fetchProject();
    },
};
</script>
