<template>
    <div class="k-flex-col">
        <div class="k-flex-row">
            <div class="flex-grow-1 mr-4">
                <b-input-group>
                    <template #prepend>
                        <b-input-group-text>
                            <b-icon-search />
                        </b-input-group-text>
                    </template>
                    <b-form-input
                        placeholder="Rechercher une sélection"
                        v-model="filter"
                        debounce="500"
                    />
                </b-input-group>
            </div>

            <div class="d-flex flex-column">
                <small class="text-kalkin-1 text-center w-100">
                    Sélections par page
                </small>
                <b-form-group>
                    <b-form-radio-group
                        v-model="perPage"
                        size="sm"
                        button-variant="outline-kalkin-1"
                        buttons
                    >
                        <b-form-radio :value="15">15</b-form-radio>
                        <b-form-radio :value="30">30</b-form-radio>
                        <b-form-radio :value="45">45</b-form-radio>
                        <b-form-radio :value="60">60</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>

            <div>
                <b-button
                    variant="kalkin-2"
                    size="sm"
                    v-b-modal.ModalCreateSelection
                >
                    <b-icon-plus-circle />
                    Nouvelle sélection
                </b-button>
            </div>
        </div>
        <b-table
            ref="selectionTable"
            class="text-break text-center"
            :items="dataProvider"
            :fields="fields"
            :current-page="currentPage"
            :filter="filter"
            :per-page="perPage"
            sort-by="name"
            show-empty
            empty-text="Aucune sélection"
            empty-filtered-text="Aucune sélection trouvée"
            stacked="md"
            hover
            tbody-tr-class="pointer"
            @row-clicked="onSelectionSelected"
            striped
            small
        >
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Chargement des données...</strong>
                </div>
            </template>
            <template #cell(guid)="data">
                <div class="k-flex-row k-flex-center k-gap-l">
                    <span class="text-monospace">{{ data.item.guid }}</span>
                    <b-button
                        size="xs"
                        variant="outline-secondary"
                        @click="copyGUID(data.item.guid)"
                    >
                        <b-icon-files /> Copier
                    </b-button>
                </div>
            </template>
            <template #cell(createdAt)="data">
                {{ data.item.createdAt | dateFromISO(true) }}
            </template>
        </b-table>
        <div>
            <b-pagination
                class="m-auto"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalRows"
                align="center"
                size="sm"
                first-number
                last-number
            />
        </div>

        <ModalCreateSelection
            :sit="sit"
            :project="project"
            @saved="onTableChange"
        />
        <ModalUpdateSelection
            :sit="sit"
            :project="project"
            :selection="selectedSelection"
            @saved="onTableChange"
        />
    </div>
</template>

<script>
import ModalCreateSelection from "./ModalCreateSelection";
import ModalUpdateSelection from "./ModalUpdateSelection";
import notifier from "@/tools/notifier";

export default {
    components: {
        ModalCreateSelection,
        ModalUpdateSelection,
    },

    props: {
        sit: { type: String, required: true },
        project: { type: Object, required: true },
    },

    data: function() {
        return {
            totalRows: 0,
            currentPage: 1,
            perPage: 15,
            fields: [
                {
                    key: "name",
                    label: "Nom de la sélection",
                    sortable: true,
                    tdClass: "text-left text-kalkin-1 pl-2",
                },
                {
                    key: "guid",
                    label: "Identifiant du flux",
                },
                {
                    key: "createdAt",
                    label: "Création",
                    sortable: true,
                },
                {
                    key: "itemCount",
                    label: "Fiches",
                    sortable: true,
                },
            ],
            filter: "",

            selectedSelection: null,
        };
    },

    watch: {
        async filter() {
            await this.fetchTotalRows();
        },
    },

    methods: {
        async fetchTotalRows() {
            this.totalRows = await this.$store.dispatch(
                "kbsit/countFSPDataSelections",
                {
                    sit: this.sit,
                    projectId: this.project.id,
                    filter: `name substring ${this.filter}`,
                },
            );
        },

        async dataProvider(ctx) {
            const items = await this.$store.dispatch(
                "kbsit/getFSPDataSelections",
                {
                    sit: this.sit,
                    projectId: this.project.id,
                    filter: `name substring ${this.filter}`,
                    sortby: (ctx.sortDesc ? "-" : "") + ctx.sortBy,
                    limit: this.perPage,
                    offset: this.perPage * (this.currentPage - 1),
                },
            );
            return items;
        },

        onSelectionSelected(sel) {
            this.selectedSelection = sel;
            if (sel) {
                this.$bvModal.show("ModalUpdateSelection");
            }
        },

        async onTableChange() {
            this.selectedSelection = null;
            this.$refs.selectionTable.refresh();
        },

        copyGUID(guid) {
            navigator.clipboard.writeText(guid);
            notifier.saved("Copié dans le presse-papier!");
        },
    },

    async beforeMount() {
        await this.fetchTotalRows();
    },
};
</script>
