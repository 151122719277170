<template>
    <b-modal
        id="ModalCreateSelection"
        title="Créer une sélection"
        size="lg"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        no-close-on-backdrop
        no-close-on-esc
        scrollable
    >
        <FormSelection
            ref="formSelectionCreate"
            :sit="sit"
            :project="project"
        />
        <template #modal-footer="{ cancel }">
            <b-button variant="outline-secondary" @click="cancel()">
                Annuler
            </b-button>
            <b-button variant="kalkin-2" @click="save">
                Enregistrer et quitter
            </b-button>
        </template>
    </b-modal>
</template>
<script>
import FormSelection from "./FormSelection";
export default {
    components: {
        FormSelection,
    },

    props: {
        sit: { type: String, required: true },
        project: { type: Object, required: true },
    },

    methods: {
        async save() {
            if (await this.$refs.formSelectionCreate.save()) {
                this.$bvModal.hide("ModalCreateSelection");
                this.$emit("saved");
            }
        },
    },
};
</script>
