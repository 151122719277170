<template>
    <div>
        <GenericForm
            v-bind="genericFormConfig"
            @updated="onUpdate"
            @validated="isValid = $event"
        />
    </div>
</template>

<script>
import { cloneDeep as _cloneDeep } from "lodash";
import GenericForm from "@/components/form-generator/GenericForm";
import Formatter from "@/tools/format";
import notifier from "@/tools/notifier";

export default {
    components: {
        GenericForm,
    },

    props: {
        sit: { type: String, required: true },
        format: { type: Object, required: true },
        project: { type: Object, required: true },
        fiche: { type: Object, required: false },
    },

    data: function() {
        const schema = this.createSchema();
        return {
            schema,
            localData: this.initLocalData(schema),

            hasChanged: false,
            isValid: false,
        };
    },

    computed: {
        genericFormConfig() {
            return {
                schema: this.schema,
                model: this.localData,
                payload: { sit: this.sit, project: this.project },
            };
        },
    },

    methods: {
        initLocalData(schema) {
            if (this.fiche) {
                return _cloneDeep(this.fiche);
            } else {
                return Formatter.createDefaultFORMObject(schema);
            }
        },

        createSchema() {
            try {
                return {
                    ...Formatter.convertCOREtoFORM(
                        _createForm(this.format, this.project),
                    ),
                };
            } catch (error) {
                notifier.error("Une erreur s'est produite, essayez plus tard.");
                return { fields: [] };
            }
        },

        onUpdate(data) {
            this.localData = data;
            this.hasChanged = true;
        },

        async save() {
            if (!this.isValid) {
                notifier.error("Il y a au moins un champ invalide.");
                return false;
            }
            if (this.hasChanged) {
                if (this.fiche) {
                    await this.$store.dispatch("kbsit/updateItem", {
                        sit: this.sit,
                        projectId: this.project.id,
                        ficheId: this.fiche.id,
                        name: this.localData.data.name, //Important data.name
                        visible: this.localData.visible,
                        data: this.localData.data,
                    });
                } else {
                    await this.$store.dispatch("kbsit/createItem", {
                        sit: this.sit,
                        projectId: this.project.id,
                        name: this.localData.data.name, //Important data.name
                        visible: this.localData.visible,
                        data: this.localData.data,
                    });
                }
            }
            return true;
        },
    },
};

function _createForm(format, project) {
    const x = [
        ...format.base,
        ...(project.meta.options || []).reduce((acc, key) => {
            acc.push(...format.options[key].base);
            return acc;
        }, []),
    ];

    for (const lang of project.meta.langs || []) {
        if (lang !== "fr") {
            let fields = _cloneDeep([
                ...(format.i18n || []),
                ...(project.meta.options || []).reduce((acc, key) => {
                    acc.push(...(format.options[key].i18n || []));
                    return acc;
                }, []),
            ]);
            fields.forEach(f => {
                f.id = `data.i18n.${lang}.${f.id}`;
                f.group = `Traductions (${lang})`;
            });
            x.push(...fields);
        }
    }
    return x;
}
</script>
