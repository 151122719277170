// Liste des langues supportées, qui doivent avoir un drapeau dans le module css : _flags.scss

module.exports = [
    {
        key: "fr",
        name: "Français",
    },
    {
        key: "en",
        name: "Anglais",
    },
    {
        key: "de",
        name: "Allemand",
    },
    {
        key: "es",
        name: "Espagnol",
    },
    {
        key: "it",
        name: "Italien",
    },
    {
        key: "nl",
        name: "Néerlandais",
    },
    {
        key: "pt",
        name: "Portugais",
    },
];
