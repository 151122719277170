<template>
    <div class="k-flex-col">
        <div>
            <FormProject
                :sit="sit"
                :format="format"
                :project="project"
                @saved="$emit('refresh')"
            />
        </div>
    </div>
</template>

<script>
import FormProject from "./FormProject";
export default {
    components: {
        FormProject,
    },

    props: {
        sit: { type: String, required: true },
        format: { type: Object, required: true },
        project: { type: Object, required: true },
    },
};
</script>
