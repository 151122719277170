<template>
    <b-modal
        id="ModalUpdateFiche"
        title="Modifier une fiche"
        size="lg"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        no-close-on-backdrop
        no-close-on-esc
        scrollable
    >
        <FormFiche
            ref="formFicheUpdate"
            :sit="sit"
            :format="format"
            :project="project"
            :fiche="fiche"
        />
        <template #modal-footer="{ cancel }">
            <b-button
                variant="outline-danger"
                @click="$bvModal.show('UpdateFiche.delete')"
            >
                Supprimer la fiche
            </b-button>
            <div class="flex-grow-1" />
            <b-button variant="outline-secondary" @click="cancel()">
                Annuler
            </b-button>
            <b-button variant="kalkin-2" @click="save">
                Enregistrer et quitter
            </b-button>
        </template>

        <ModalDanger
            uid="UpdateFiche.delete"
            @confirmed="onDeleteConfirmed"
            message="Voulez vous vraiment supprimer cette fiche?"
            details="Ses médias associés ne seront pas automatiquement supprimés."
        />
    </b-modal>
</template>
<script>
import FormFiche from "./FormFiche";
import ModalDanger from "@/components/modal/ModalDanger";
export default {
    components: {
        FormFiche,
        ModalDanger,
    },

    props: {
        sit: { type: String, required: true },
        format: { type: Object, required: true },
        project: { type: Object, required: true },
        fiche: { type: Object, required: false },
    },

    methods: {
        async save() {
            if (await this.$refs.formFicheUpdate.save()) {
                this.$bvModal.hide("ModalUpdateFiche");
                this.$emit("saved");
            }
        },

        async onDeleteConfirmed() {
            await this.$store.dispatch("kbsit/deleteItem", {
                sit: this.sit,
                projectId: this.project.id,
                ficheId: this.fiche.id,
            });
            this.$bvModal.hide("ModalUpdateFiche");
            this.$emit("saved");
        },
    },
};
</script>
