<template>
    <div class="k-flex-col">
        <TableAssos :sit="sit" :project="project" />
    </div>
</template>

<script>
import TableAssos from "./TableAssos";
export default {
    components: {
        TableAssos,
    },

    props: {
        sit: { type: String, required: true },
        project: { type: Object, required: true },
    },
};
</script>
