<template>
    <b-overlay
        :show="isLoading || lock"
        class="k-flex-col"
        spinner-variant="kalkin-1"
        rounded
    >
        <div class="k-flex-row">
            <div class="flex-grow-1">
                <b-input-group size="sm">
                    <template #prepend>
                        <b-input-group-text>
                            <b-icon-search />
                        </b-input-group-text>
                    </template>
                    <b-form-input
                        placeholder="Rechercher une fiche"
                        v-model="filter"
                        debounce="300"
                    />
                </b-input-group>

                <b-input-group size="sm"> </b-input-group>
            </div>
            <b-input-group size="sm" style="flex:1;min-width:300px;">
                <template #prepend>
                    <b-input-group-text>
                        <b-icon-sort-alpha-down />
                    </b-input-group-text>
                </template>
                <b-form-select
                    v-model="sortby"
                    :options="sortOptions"
                    @change="refreshTable"
                />
            </b-input-group>
        </div>
        <div>
            <b-table
                ref="tableAssos"
                class="text-break text-center"
                :items="dataProvider"
                :fields="fields"
                :current-page="currentPage"
                :filter="filter"
                :per-page="perPage"
                show-empty
                hover
                striped
                sticky-header="70vh"
                fixed
                no-border-collapse
                small
            >
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-2">Chargement des données...</strong>
                    </div>
                </template>
                <template #empty>
                    <div class="text-left p-5">
                        <b-icon-x-lg class="text-danger" />
                        Aucune fiche
                    </div>
                </template>
                <template #emptyfiltered>
                    <div class="text-left p-5">
                        <b-icon-x-lg class="text-danger" />
                        Aucune fiche trouvée
                    </div>
                </template>
                <template #cell(name)="data">
                    <div>
                        <div class="name">
                            {{ data.item.name }}
                        </div>
                        <small class="k-flex-row text-secondary">
                            <div>
                                Créée le
                                {{ data.item.createdAt | dateFromISO(true) }}
                            </div>
                            <div>
                                {{ data.item.uses || 0 }}
                                <b-icon-journals />
                            </div>
                        </small>
                    </div>
                </template>
                <template #cell()="data">
                    <b-form-checkbox
                        :checked="data.item.selections[data.field.key]"
                        size="lg"
                        @input.once="
                            updateAsso(
                                data.item.id,
                                data.field.key,
                                data.item.selections[data.field.key],
                            )
                        "
                        :disabled="lock"
                        switch
                    >
                    </b-form-checkbox>
                </template>
            </b-table>
            <div>
                <b-pagination
                    class="m-auto"
                    v-model="currentPage"
                    :per-page="perPage"
                    :total-rows="totalRows"
                    align="center"
                    size="sm"
                    first-number
                    last-number
                />
            </div>
        </div>
    </b-overlay>
</template>

<script>
import { sortBy as _sortBy, reverse as _reverse } from "lodash";
export default {
    props: {
        sit: { type: String, required: true },
        project: { type: Object, required: true },
    },

    data: function() {
        return {
            isLoading: false,
            filter: "",
            sortby: null,
            sortOptions: [
                { value: null, text: "Trier par nom" },
                {
                    value: { field: "createdAt" },
                    text: "Trier du moins au plus récent",
                },
                {
                    value: { field: "createdAt", reverse: true },
                    text: "Trier du plus au moins récent",
                },
                {
                    value: { field: "uses" },
                    text: "Trier du moins au plus utilisé",
                },
                {
                    value: { field: "uses", reverse: true },
                    text: "Trier du plus au moins utilisé",
                },
            ],
            currentPage: 1,
            perPage: 50,
            totalRows: 0,

            fiches: [],
            fichesMap: {},
            selections: [],
            assos: [],

            lock: false,
        };
    },

    computed: {
        fields() {
            return [
                {
                    key: "name",
                    label: "Fiche",
                    stickyColumn: true,
                    tdClass: "k-cell-fiche",
                    thStyle: "width:300px;",
                },
                ...this.selections.map(s => {
                    return {
                        key: `${s.id}`,
                        label: s.name,
                        thStyle: "width:200px;",
                        thClass: "bg-kalkin-2",
                    };
                }),
            ];
        },
    },

    methods: {
        async fetchFiches() {
            this.isLoading = true;
            this.fiches = await this.$store.dispatch("kbsit/getAssosItems", {
                sit: this.sit,
                projectId: this.project.id,
            });
            this.fichesMap = this.fiches.reduce((acc, f) => {
                acc[f.id] = f;
                return acc;
            }, {});
            this.isLoading = false;
        },

        async fetchSelections() {
            this.isLoading = true;
            this.selections = await this.$store.dispatch(
                "kbsit/getAssosDataSelections",
                {
                    sit: this.sit,
                    projectId: this.project.id,
                },
            );
            this.isLoading = false;
        },

        async fetchAssos() {
            this.isLoading = true;
            this.assos = await this.$store.dispatch("kbsit/getAssos", {
                sit: this.sit,
                projectId: this.project.id,
            });
            for (const fiche of this.fiches) {
                fiche.uses = 0;
                fiche.selections = {};
            }
            for (const { itemId, dataSelectionOutId } of this.assos) {
                this.fichesMap[itemId].selections[
                    `${dataSelectionOutId}`
                ] = true;
                this.fichesMap[itemId].uses++;
            }
            this.isLoading = false;
        },

        async updateAsso(itemId, selectionId, oldState) {
            this.lock = true;
            setTimeout(async () => {
                await this.delayedUpdateAsso(itemId, selectionId, oldState);
            }, 300);
        },

        async delayedUpdateAsso(itemId, selectionId, oldState) {
            let success;
            let fiche = this.fichesMap[itemId];
            if (oldState) {
                success = await this.$store.dispatch("kbsit/deleteAsso", {
                    sit: this.sit,
                    projectId: this.project.id,
                    itemId,
                    selectionId,
                });
            } else {
                success = await this.$store.dispatch("kbsit/createAsso", {
                    sit: this.sit,
                    projectId: this.project.id,
                    itemId,
                    selectionId,
                });
            }
            if (success) {
                fiche.selections[`${selectionId}`] = !oldState;
                fiche.uses += oldState ? -1 : 1;
            }
            this.refreshTable();
            this.lock = false;
        },

        refreshTable() {
            if (this.$refs.tableAssos) {
                this.$refs.tableAssos.refresh();
            }
        },

        dataProvider({ filter }) {
            // Filter
            const filtered = this.fiches.filter(f =>
                f.name.toLowerCase().includes(filter.toLowerCase()),
            );

            // Sort
            let result = _sortBy(filtered, ["name"]);
            if (this.sortby) {
                if (this.sortby.reverse) {
                    _reverse(result); // Pour conserver tri secondaire par nom
                }
                result = _sortBy(result, [this.sortby.field]);
                if (this.sortby.reverse) {
                    _reverse(result);
                }
            }

            // Paginate
            this.totalRows = filtered.length;
            this.currentPage = Math.min(
                this.currentPage,
                Math.ceil(this.totalRows / this.perPage) || 1,
            );
            return result.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage,
            );
        },
    },

    async beforeMount() {
        await this.fetchFiches();
        await this.fetchSelections();
        await this.fetchAssos();
        this.refreshTable();
    },
};
</script>

<style lang="scss">
.k-cell-fiche {
    background-color: #37b39a !important;
    color: $primary-color-darker !important;
    text-align: left;
    padding-left: 0.5em !important;

    & .name {
        font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
    }
}
</style>
